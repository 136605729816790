@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap');

:root {
  --primary-gold: rgb(235, 196, 114);
  --primary-gold2: rgb(232, 175, 54);
  --primary-white: #ffffff;
  --primary-light-grey: #f4f4f4;
  --primary-orange: #FF871A;
  --primary-green: #4AB834;
  --primary-dark: rgb(255, 255, 255, 0);
  --primary-dark2: rgb(36, 39, 41);
  --primary-grey: rgb(186, 191, 195);
  --primary-black: rgb(6, 7, 7);
  --primary-transparent-black: rgba(0, 0, 0, 0.5);
  --primary-med-grey: rgb(172, 183, 195);
  --primary-red: #f04019;
  --primary-hover-red: #bf2402;
  --primary-dark-grey: rgb(33, 33, 34);
  --primary-placeholder: #5d6469;
}

body {
  font-family: 'Josefin Sans', sans-serif !important;

}

/************************Navbar***********************/
nav {
  z-index: 2;
}

.logo {
  width: 16.25rem;
  height: auto;
}

/*********** MENU LINKS ***************/
nav a.nav-link {
  color: var(--primary-black) !important;
  font-weight: 200;
  letter-spacing: 0.0375rem;
  cursor: pointer;
}

nav ul li {
  text-transform: uppercase;
}

.navbar-nav>li>a {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  color: var(--primary-gold) !important;
  border-top: 0.1875rem solid var(--primary-dark);
  border-bottom: 0.1875rem solid var(--primary-dark);

}

.navbar-brand {
  max-width: 17.25rem;
  /* padding: 0 !important; */
  /* padding-bottom: 0.2rem !important; */
  height: 5rem !important;
}

@media(max-width: 767px) {
  .navbar-brand {
    max-width: 5rem;
  }
}

.navbar-nav>li>a.active, .navbar-nav>li>a:focus {
  border-bottom: 0.1875rem solid var(--primary-gold);
}

.navbar-nav>li>a:hover {
  border-bottom: 0.1875rem solid var(--primary-gold);
  transition: transform 250ms;
  transform: translateY(0.3rem);
}

/*********** HEADER ***************/
.header-wraper {
  position: relative;
  background-size: cover;
  background-position: center;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: 'Dosis', sans-serif;
  font-size: 25px;
  font-weight: 800;
}

.backgroundFrame {
  background-color: rgba(0, 0, 0, .5);
  color: #fff;
  padding: 1rem;
  z-index: 1;
  position: absolute;
}

.main-info {
  display: flex;
  position: absolute;
  top: 50%;
  left: 30%;
  right: 30%;
  bottom: 40%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Josefin Sans', sans-serif !important;
  z-index: 1;
}

.btn-main-offer a.nav-link {
  color: var(--primary-black) !important;
  font-weight: 200;
  letter-spacing: 0.0375rem;
  cursor: pointer;
}

@media(max-width: 767px) {
  .btn-main-offer {
    max-width: 15;
  }
}

.logo2 {
  width: 25rem;
  height: auto;
  padding-top: 1.5rem;
}

@media(max-width: 767px) {
  .logo2 {
    max-width: 19rem;
  }
}


/********HEADER MAIN OFFER BUTTON**********/

.btn-main-offer {
  border: 1px solid var(--primary-gold);
  text-transform: uppercase;
  border-radius: 0.125rem;
  padding: 0.3rem 0.3rem 0.3rem 0.3rem;
  background-color: var(--primary-gold);
  color: var(--primary-black);
  text-decoration: none;
  margin: 2rem;
}

.btn-main-offer:hover {
  border: 1px solid var(--primary-gold2);
  background-color: var(--primary-gold2);
  color: var(--primary-black);
  text-decoration: none;
  margin: 2rem;
  transform: translateY(10px);
  transition: transform 0.4s ease-in-out;
  box-shadow: 4px 8px 15px 2px rgba(0, 0, 0, 0.3);
}


/**********Services*******/

.services {
  text-align: center;
  padding-bottom: 2.5rem;
  background-color: var(--primary-dark2);
}

.services h1 {
  color: var(--primary-gold);
  text-transform: uppercase;
}

.services h3 {
  font-size: 1.7rem;
  color: var(--primary-gold);

}

.services .circle {
  position: relative;
  margin: 0.375rem auto;
  background: var(--primary-gold);
  border-radius: 5%;
  margin-bottom: 2rem;
  width: 3rem;
  height: 3rem;
}


.services .icon {
  font-size: 1.8rem;
  color: var(--primary-black);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.services .box {
  height: 14rem;
  border-bottom: 0.3125rem solid var(--primary-gold);
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  background: var(--primary-dark2);
  text-transform: uppercase;

}

.services .box:hover {
  background: var(--primary-gold);
  /* border-bottom: 0.3125rem solid var(--primary-white); */
  transform: translateY(10px);
  transition: transform 0.4s ease-in-out;
  box-shadow: 4px 8px 15px 2px rgba(0, 0, 0, 0.3);
}

.services .box:hover .icon {
  color: var(--primary-black);
}

.services .box:hover .circle {
  background: var(--primary-white);
}

.services .box:hover p {
  color: var(--primary-white);
}

.services .box:hover h3 {
  color: var(--primary-black);
}

/**********  Testimonials *******/

.testimonials {
  background-image: url(testimonials-bg.jpg);
  background-size: cover;
  background-color: var(--primary-black);
  text-align: center;
  padding-bottom: 1rem;
  margin-bottom: 0;
  width: 100%;
}

.testimonials h1 {
  color: var(--primary-gold);
  padding: 1rem 0 0 0 !important;
  text-transform: uppercase;
}

.testimonials-content {
  text-align: center;
  max-width: 53.125rem;
  margin: 0 auto 1rem auto;
  background: var(--primary-transparent-black);

}

.myCarousel {
  color: var(--primary-gold);
  background: transparent;
  margin-top: -6%;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  height: auto;
}

.myCarousel h3 {
  color: var(--primary-gold);
  font-weight: 600;
  text-transform: uppercase;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.carousel .slide img {
  width: 10rem !important;
  border-radius: 2%;
  padding-top: 1rem;
}

.carousel .slide {
  background: transparent !important;
  height: 100% !important;
  padding-top: 2rem;
}

.carousel-root {
  margin: auto !important;
  margin-top: 3% !important;
}

/*********** About Us ***************/

.photo-wrap {
  display: flex;
  justify-content: center;
}

.profile-img {
  width: 16rem;
  border-radius: 2%;
  padding: 0.1rem;
  /* margin-bottom: 0rem; */
  border: 1px solid var(--primary-gold);
}

.about-heading {
  color: var(--primary-gold);
  text-transform: uppercase;
}

@media(max-width: 468px) {
  .profile-img {
    width: 15rem;
  }
}

/**********Reques-Quote******/

.contacts {
  background: var(--primary-dark2);
  padding: 4.5rem 0;
}

.contacts h1 {
  color: var(--primary-gold);
  padding: 1.3rem 0;
  text-transform: uppercase;
}

.contacts p {
  color: var(--primary-white);
  font-size: 1.2rem;
}

/**********Inputs******/


.contacts input, .contacts textarea {
  width: 100%;
  box-sizing: border-box;
  background: none;
  resize: none;
  border: 0;
  border-bottom: 0.125rem solid var(--primary-med-grey);
  border-radius: 0;
  color: var(--primary-light-grey);
  outline: 0 !important;
  box-shadow: none !important;
  top: 0;
  margin-bottom: 1rem;
}

.contacts input::placeholder, .contacts textarea::placeholder {
  color: var(--primary-placeholder);
}

.contacts .container {
  padding: 4rem 0;
  background: transparent;
  margin-bottom: 1.875rem;

}

.contacts input:focus, .contacts textarea:focus {
  background: transparent;
  color: var(--primary-white);
  border-bottom: 0.125rem solid var(--primary-med-grey);
}

.contacts .add-height {
  height: 12.2rem !important;
}

.contacts textarea {
  height: 8.9rem !important;
}

.line {
  position: relative;
  top: -0.7rem;
  width: 0;
  height: 0.2rem;
  background: var(--primary-gold);
  display: inline-block;
}

input:focus+.line, textarea:focus+.line {
  width: 100%;
  transition: width 0.5s ease-in-out;
}

input.form-control {
  margin: -0.3rem 0;
}

textarea.form-control {
  margin: -0.3rem 0;
}

.contacts button {
  margin: 2rem 0 0 0;
  width: 30%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  position: absolute;
  transform: translateX(-50%);
  background: var(--primary-gold);
  text-transform: uppercase;
  border: none;
  font-weight: 800;
}

.contacts button:hover {
  margin: 1.95rem 0 0 0;
  width: 30%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  position: absolute;
  transform: translateX(-50%); 
  transition: 200ms ease-in-out;
  background: var(--primary-gold2);
}

.error-message {
  color: var(--primary-white);
  background: var(--primary-red);
  text-align: left;
  margin-top: 0.125rem;
}

.success-message {
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--primary-green);
}

.contacts .checkBox {
  display: flex;
  /* align-items: center; */
  margin: 1px 0px 0px 0px;
}

.contacts .checkBox input {
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  margin-right: 15px;
}

.page {
  padding-top: 140px;
  background-color: var(--bs-body-bg);
  max-width: 100%;
  width: 100%;
}

.contacts .checkBox label {
  color: var(--primary-med-grey);
  line-height: initial;
}

.contacts a {
  color: var(--primary-light-grey);
}

.contacts a:hover {
  text-decoration: none;
}

/**********Media******/

@media(max-width: 767px) {
  .contacts {
    overflow-x: hidden;
  }

  .contacts .container {
    padding: 0 1rem;
  }

  .contacts p {
    padding: 0 0.8rem;
  }
}

/**********Footer******/

.footer {
  margin-top: auto;
  background: var(--primary-black);
  color: var(--primary-gold);
  padding: 1.5rem;
  font-size: 1.25rem;
}

.footer a {
  color: var(--primary-gold);
  text-decoration: none;
}

.footer a:hover {
  color: var(--primary-white);
  text-decoration: none;
  cursor: pointer;
}

.footer p {
  margin-bottom: 0;
}

a.instagram {
  color: #d92e7f !important;
  margin: 10px;

}



a.facebook {
  color: #4968ad !important;
  margin: 10px;
}

a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
}

a.social:hover {
  transform: translateY(-4px);
}


.pageTitle {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 20px;
}

.page h2 {
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 20px;
}

.page p {
  font-size: 22px;
  margin-bottom: 20px;
  max-width: 100%;
  width: 100%;
}

.page ul {
  list-style: none;
}

.pl-0 {
  padding-left: 0;
}

.page a {
  text-decoration: none;
  color: var(--bs-body-color);
  width: 100%;
  word-wrap: break-word;
}
.page a:hover {
  text-decoration: underline;
}

@media(max-width: 467.98px) {

  .contacts {
    padding: 4.5rem 0 9rem 0;
  }

  .page {
    padding-top: 130px;
  }
  
  .pageTitle {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .page h2 {
    font-weight: 500;
    font-size: 25px;
    margin-bottom: 15px;
  }
  
  .page p {
    font-size: 18px;
    margin-bottom: 15px;
  }

}